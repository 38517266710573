<template>
	<main>
		<el-dialog title="历史版本" :visible="visible" @close="close" :append-to-body="true">
			<div class="table-box">
				<el-table size="small" ref="multipleTable" :data="table_data" row-key="id" tooltip-effect="dark" v-loading="table_loading" :tree-props="{children: 'children', hasChildren: 'hasChildren'}" >
					<el-table-column label="版本号" prop="id" width="100"></el-table-column>
					<el-table-column label="数据地址" prop="url"></el-table-column>
					<el-table-column label="操作" prop="action" width="200">
						<template slot-scope="scope">
							<div class="el-link-group">
								<el-link type="primary" @click="toPreview(scope.row)">预览</el-link>
								<el-link type="primary" @click="toPush(scope.row)">重新发布</el-link>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="page-box">
				<el-pagination @size-change="pageSize" @current-change="pageCurrent" :current-page="page_info.page" :page-sizes="[10, 15, 20, 50,100]" :page-size="page_info.limit" layout="total, sizes, prev, pager, next, jumper" :total="page_info.total" hide-on-single-page background></el-pagination>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close">取 消</el-button>
				<el-button type="primary" @click="submit">确 定</el-button>
			</div>
		</el-dialog>
	</main>
</template>

<script>
import {version} from "@/api/admin";
import axios from "axios";
import moment from "moment";

export default {
	name: 'index',
	data(){
		return {
			column_visible:[],
			visible:false,
			table_loading:false,
			search_data:{},
			page:1,
			limit:10,
			table_data:[],
			page_info:{
				total:0,
				page:1,
				limit:10,
			},
		}
	},
	computed:{
		column(){
			return [
				{label:"版本号",prop:"id",search:true},
				{label:"数据地址",prop:"url"},
				{label:"发布时间",prop:"create_time",type:"datetime"},
				{label:"操作",prop:"action", fixed:'right',type:'action'},
			]
		}
	},
	watch:{

	},
	mounted() {

	},
	methods:{
		toPreview(row){
			const url = row.url;
			let version = url ? url.split('/') : [];
			if(version && version.length > 0){
				version = version[version.length - 1];
				window.open('/?version='+version)
			}
		},
		toPush(row){
			this.$confirm('确定要发布吗？','提示').then(()=>{
				axios.get(row.url).then((content)=>{
					const filename = moment().format('YYYYMMDDHHmmss')+'.json';
					this.$emit('reset',{content:content.data,filename})
				});

			})
		},
		pageSize(limit){
			this.page = 1;
			this.limit = limit;
			this.all();
		},
		pageCurrent(page){
			this.page = page;
			this.all();
		},
		all(){
			this.table_loading = true;
			const params = Object.assign({},{
				page:this.page,
				limit:this.limit,
				order:this.order,
			},this.search_data)
			version.all(params).then((res)=>{
				this.table_data = res.list;
				this.page_info = res.page_info;
				this.$nextTick(()=>{
					this.table_loading = false;
				})
			})
		},
		init(){
			this.visible = true;
			this.all();
		},
		submit(){
			this.close();
		},
		close(){
			this.visible = false;
		}
	}
}
</script>

<style lang="scss" scoped></style>

